import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";

const userDetials = () => {
  const [users, setUsers] = useState([]);
  const API_URL = process.env.React_App_Backend_URL;

  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const token = sessionStorage.getItem("token"); // Get the token from session storage

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}user/getUsers`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      if (response.status === 200) {
        setUsers(response.data);
        console.log("user data", response.data);
      }
    } catch (error) {
      console.error("Error fetching users detials:", error);
      setErrorMessage(
        `Error fetiching user detials Seesion has expired please login agian: ${error}`
      );
      setErrorPopup(true);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [token]);
  return (
    <div className="overflow-x-auto sm:py-6 sm:px-6 sm:mx-4 rounded-2xl shadow-2xl bg-white">
      <div>
        {users.length > 0 && (
          <div className="max-w-full mx-auto p-1 mx-2 pb-8 md:pt-8 md:px-16 md:pb-16 ">
            <h2
              className="sm:text-lg md:text-xl xl:text-2xl font-bold text-center mb-4"
              style={{ color: "#375BD2" }}
            >
              User Details
            </h2>
            <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
              <table className="table-auto min-w-full border-collapse border">
                <thead>
                  <tr
                    className="mb-4"
                    style={{ backgroundColor: "#375BD2", color: "white" }}
                  >
                    <th className="px-1 py-2 border text-xs sm:text-sm md:text-md text-center">
                      No
                    </th>
                    <th className="px-1 py-2  border text-xs sm:text-sm md:text-md text-center">
                      User Name
                    </th>
                    <th className="px-1 py-2  border text-xs sm:text-sm md:text-md ">
                      Email
                    </th>
                    <th className="px-1 py-2  border text-xs sm:text-sm md:text-md ">
                      User Register Date
                    </th>
                  </tr>
                </thead>
                <tbody className="flex-col bg-white items-center">
                  {users.map((user, index) => (
                    <tr key={user._id} className="hover:bg-gray-100 ">
                      <td className=" pl-4 md:pl-8 lg:pl-16 xl:pl-20 pr-4 py-1 border text-xs sm:text-sm md:text-md ">
                        {index + 1}
                      </td>
                      <td className=" pl-4 md:pl-8 lg:pl-16 xl:pl-20 pr-4 py-1  border text-xs sm:text-sm md:text-md">
                        {user.username}
                      </td>
                      <td className="pl-4 md:pl-8 lg:pl-24 xl:pl-28 pr-4 py-1  text-xs sm:text-sm md:text-md border ">
                        {user.email}
                      </td>
                      <td className="pl-4 md:pl-8 lg:pl-24 xl:pl-28 pr-4 py-1  text-xs sm:text-sm md:text-md border ">
                        {format(
                          new Date(user.userRegisterDate),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {errorPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
            <div className="text-center my-4">
              <p className="text-lg font-bold text-red-800">Error</p>
              <div className="mt-6 mb-4">{errorMessage}</div>
              <button
                onClick={() => setErrorPopup(false)}
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 cursor-pointer"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default userDetials;
