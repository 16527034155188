import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/login/Login";
import Signup from "./components/login/SignUp";
import MainView from "./components/MainView";
import ContractInteraction from "./components/subComponents/ContractInteraction";
import ContractForm from "./contracts/ContractForm";
import UserDetails from "./components/subComponents/userDetials"; // Make sure to use correct file path
import FAQComponent from "./components/subComponents/FAQComponent";
const checkAuthentication = () => {
  const token = sessionStorage.getItem("token");
  const tokenExpiration = sessionStorage.getItem("tokenExpiration");

  if (token && tokenExpiration) {
    const expirationTime = parseInt(tokenExpiration, 10);
    const currentTime = Date.now() / 1000;

    return currentTime <= expirationTime;
  }
  return false;
};

const App = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [showTokenExpiredPopup, setShowTokenExpiredPopup] = useState(false);

  useEffect(() => {
    const isAuthenticated = checkAuthentication();

    if (isAuthenticated) {
      setUserAuthenticated(true);

      const intervalId = setInterval(() => {
        const isAuthenticated = checkAuthentication();
        setUserAuthenticated(isAuthenticated);
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const handleLogin = (token: any) => {
    const expirationTime = calculateTokenExpirationTime();
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("tokenExpiration", expirationTime.toString());
    setUserAuthenticated(true);
    setShowTokenExpiredPopup(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("tokenExpiration");
    setUserAuthenticated(false);
  };

  const handleCloseTokenExpiredPopup = () => {
    setShowTokenExpiredPopup(false);
    window.location.href = "/login";
  };

  const calculateTokenExpirationTime = () => {
    const expirationDuration = 3600; // 1 hour in seconds
    return Date.now() / 1000 + expirationDuration;
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/signup"
          element={<Signup onSignup={handleLogout} onLogin={handleLogin} />}
        />
        {userAuthenticated && (
          <>
            <Route path="/mainview/*" element={<MainView />}>
              <Route path="projects" element={<ContractInteraction />} />
              <Route
                path="addcontract"
                element={<ContractForm account={""} />}
              />
              <Route path="users" element={<UserDetails />} />
              <Route path="faq" element={<FAQComponent />} />
            </Route>

            <Route
              path="/mainview"
              element={<Navigate to="/mainview/projects" />}
            />
          </>
        )}
        <Route path="/" element={<Login onLogin={handleLogin} />} />
      </Routes>

      {showTokenExpiredPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Your session has expired. Please log in again.</p>
            <button onClick={handleCloseTokenExpiredPopup}>OK</button>
          </div>
        </div>
      )}
    </Router>
  );
};

export default App;
