import React, { useState } from "react";
import DisconnectModal from "./DisconnectModal";

interface DisconnectWalletProps {
  connectedAccount: string | null;
  onDisconnect: () => void;
}

const DisconnectWallet: React.FC<DisconnectWalletProps> = ({
  connectedAccount,
  onDisconnect,
}) => {
  const [showModal, setShowModal] = useState(false);

  const disconnectWallet = () => {
    setShowModal(true);
  };

  const cancelDisconnect = () => {
    setShowModal(false);
  };

  const confirmDisconnect = () => {
    setShowModal(false);
    // Trigger the onDisconnect callback
    if (onDisconnect) {
      onDisconnect();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {connectedAccount ? (
        <button
          className="rounded-md"
          onClick={disconnectWallet}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="text-center">
            <span className="text-500 font-bold mr-1">
              {connectedAccount.slice(0, 6)}
            </span>
            <span className="text-50 font-bold">...</span>
            <span className="text-500 font-bold ml-1">
              {connectedAccount.slice(-6)}
            </span>
          </div>
        </button>
      ) : (
        <button onClick={disconnectWallet}>Connect Wallet</button>
      )}

      {showModal && (
        <DisconnectModal
          onCancel={cancelDisconnect}
          onDisconnect={confirmDisconnect}
          userAddress={connectedAccount || ""} // Pass connectedAccount as userAddress
        />
      )}
    </div>
  );
};

export default DisconnectWallet;
