import React, { useEffect, useState } from "react";
import Web3 from "web3";
import {
  setWalletData,
  getWalletData,
  switchNetwork,
} from "../../utils/walletData";

interface ConnectWalletProps {
  onConnect: (account: string, chainId: string) => void;
}

const ConnectWallet: React.FC<ConnectWalletProps> = ({ onConnect }) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [chainId, setChainId] = useState<string | null>(null);

  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        const currentAccount = accounts[0];
        setAccount(currentAccount);

        window.ethereum.on("accountsChanged", handleAccountsChanged);

        window.ethereum.on("chainChanged", handleChainChanged);

        if (onConnect) {
          const currentChainId: bigint = await web3Instance.eth.getChainId();
          const chainIdString: string = currentChainId.toString();
          setChainId(chainIdString);
          onConnect(currentAccount, chainIdString);
          setWalletData(currentAccount, chainIdString);
        }

        window.ethereum.sendAsync(
          {
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }],
          },
          (error: any, response: any) => {
            if (error) {
              console.error("Error connecting to MetaMask:", error);
            }
          }
        );
      } else {
        console.error("MetaMask is not installed");
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };


  const handleAccountsChanged = async (accounts: string[]) => {
    try {
      const ethereum = window.ethereum;

      if (ethereum) {
        const currentAccount = accounts[0];
        setChainId((prevChainId) => prevChainId);
        setAccount(currentAccount);
        onConnect(currentAccount, chainId || "");

        const web3 = new Web3(window.ethereum);

        const currentChainId = ethereum
          ? await ethereum.request({ method: "eth_chainId" })
          : null;
        const chainIdString: string = currentChainId
          ? currentChainId.toString()
          : "";

        setWalletData(currentAccount, chainIdString);
      } else {
        console.error(
          "MetaMask or Ethereum provider not found. Please install MetaMask."
        );
      }
    } catch (error) {
      console.error("Error in handleAccountsChanged:", error);
    }
  };

  const handleChainChanged = (newChainId: string) => {
    setChainId(newChainId);
    window.dispatchEvent(
      new CustomEvent("networkSwitch", { detail: newChainId })
    );
    setWalletData(account, newChainId);
  };

  useEffect(() => {
    setAccount(account);
    setWalletData(account || "", chainId || "");
  }, [chainId, account]);

  useEffect(() => {

    const handleNetworkSwitch = (event: CustomEvent) => {
      
      console.log("Network switch event. New chain ID:", event.detail);
    };

    if (web3) {
      window.addEventListener(
        "networkSwitch",
        handleNetworkSwitch as EventListener
      );
    }

    return () => {
      if (web3) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
        window.removeEventListener(
          "networkSwitch",
          handleNetworkSwitch as EventListener
        );
      }
    };
  }, [web3, chainId]);

  return (
    <div>
      {account ? (
        <div>
          <p>Your wallet address: {account}</p>
        
        </div>
      ) : (
        <button
          onClick={connectToMetaMask}
          className="rounded-md"
          style={{ display: "flex", alignItems: "center" }}
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default ConnectWallet;
