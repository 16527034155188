import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConnectMetamask, DetectMetamask } from "../../metamask";
import logo from "../../assets/images/logo.svg";
import { IoMenu } from "react-icons/io5";
import { RiCloseLine } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";

interface NavbarProps {
  setUserAccount: React.Dispatch<React.SetStateAction<string | null>>;
}

const Navbar: React.FC<NavbarProps> = ({ setUserAccount }) => {
  const [clicked, setClicked] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState<string | null>(null);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(true);
  };

  const handleConnect = (account: string) => {
    setConnectedAccount(account);
    setUserAccount(account); // Set userAccount in MainView
  };

  const handleDisconnect = () => {
    setConnectedAccount(null);
    setUserAccount(null); // Set userAccount to null in MainView
  };

  const handleLogout = () => {
    setShowLogoutPopup(true);
  };

  const confirmLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    navigate("/login");
    setShowLogoutPopup(false);
  };

  const cancelLogout = () => {
    setShowLogoutPopup(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setClicked(!clicked);
  };

  const handleToggleConnect = () => {
    toggleMobileMenu();
  };

  // Check if the user is logged in as an admin
  const isAdmin = sessionStorage.getItem("role") === "admin";

  return (
    <div className="flex justify-between items-center h-24 bg-white p-6 shadow">
      <div className="flex justify-center items-center">
        <Link
          to="https://www.blocsys.com/"
          className="text-blue-500 hover:underline font-semibold"
        >
          <img src={logo} className="mr-2 h-14 w-56" alt="Logo" />
        </Link>
      </div>

      <div className="hidden md:flex items-center text-sm md:text-md lg:text-2xl space-x-10 ml-4 justify-center">
        <Link
          to="projects"
          className={`hover:underline  ${
            clicked ? "font-bold" : "font-semibold"
          }`}
          style={{ color: "#375BD2" }}
          onClick={handleClick}
        >
          Projects
        </Link>
        <Link
          to="addcontract"
          className={`text-blue-500 hover:underline ${
            clicked ? "font-bold" : "font-semibold"
          }`}
          style={{ color: "#375BD2" }}
          onClick={handleClick}
        >
          Add Contract
        </Link>

        {isAdmin && (
          <Link
            to="users"
            className={`text-blue-500 hover:underline ${
              clicked ? "font-bold" : "font-semibold"
            }`}
            style={{ color: "#375BD2" }}
            onClick={handleClick}
          >
            Users
          </Link>
        )}
        <Link
          to="faq"
          className={`text-blue-500 hover:underline ${
            clicked ? "font-bold" : "font-semibold"
          }`}
          style={{ color: "#375BD2" }}
          onClick={handleClick}
        >
          FAQs
        </Link>
      </div>

      <div className="hidden md:flex items-center space-x-10 ml-4">
        <button className="inline-flex items-center px-5 py-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          {connectedAccount ? (
            <DetectMetamask
              connectedAccount={connectedAccount}
              onDisconnect={handleDisconnect}
            />
          ) : (
            <ConnectMetamask onConnect={handleConnect} />
          )}
        </button>

        <button
          className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleLogout}
        >
          <span className="flex font-bold">
            Logout <IoMdLogOut className=" ml-1 h-6 w-6 " />
          </span>
        </button>
      </div>

      <button
        className="md:hidden bg-blue-800 text-white ml-6 px-4 py-2  rounded-md "
        style={{ backgroundColor: "#3a59d5", color: "white" }}
        onClick={toggleMobileMenu}
      >
        {clicked ? (
          <IoMenu className="h-6 w-8" />
        ) : (
          <RiCloseLine className="h-6 w-8" />
        )}
      </button>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute h-full mt-1 top-24 left-0 h-3/4 w-11/12 bg-white z-10 p-4 shadow-2xl rounded-r-lg">
          <div className="flex items-center flex-col space-y-2">
            <Link
              to="projects"
              className="hover:underline text-xl font-semibold"
              style={{ color: "#375BD2" }}
              onClick={toggleMobileMenu}
            >
              Projects
            </Link>
            <Link
              to="addcontract"
              className="text-blue-500 text-xl hover:underline font-semibold"
              style={{ color: "#375BD2" }}
              onClick={toggleMobileMenu}
            >
              Add Contract
            </Link>

            {isAdmin && (
              <Link
                to="users"
                className="text-blue-500 text-xl hover:underline font-semibold"
                style={{ color: "#375BD2" }}
                onClick={toggleMobileMenu}
              >
                Users
              </Link>
            )}
          </div>
          <Link
            to="faq"
            className="text-blue-500 text-xl flex flex-col text-center hover:underline font-semibold"
            style={{ color: "#375BD2" }}
            onClick={toggleMobileMenu}
          >
            FAQs
          </Link>

          <div className="flex flex-col space-y-2 mt-4">
            <button
              className="bg-blue-500 text-white px-8 py-3 rounded-md text-centre"
              style={{ backgroundColor: "#3a59d5" }}
              onClick={() => {
                handleToggleConnect();
              }}
            >
              {connectedAccount ? (
                <DetectMetamask
                  connectedAccount={connectedAccount}
                  onDisconnect={handleDisconnect}
                />
              ) : (
                <ConnectMetamask onConnect={handleConnect} />
              )}
            </button>
            <button
              className="bg-blue-500 text-white px-8 py-3 rounded-md flex items-center justify-center"
              style={{ backgroundColor: "#3a59d5" }}
              onClick={() => {
                toggleMobileMenu();
                handleLogout();
              }}
            >
              <span className="flex font-bold">
                Logout <IoMdLogOut className="h-6 w-8 ml-2 " />
              </span>
            </button>
          </div>
        </div>
      )}

      {/* Logout Popup */}
      {showLogoutPopup && (
        <div className="fixed mt-20 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 h-60 w-60 sm:w-80 md:w-96 lg:w-96 rounded-md shadow-md  ">
            <p className="flex justify-center font-bold mb-4 mt-12">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-center mt-10">
              <button
                className="mr-2 bg-red-500 text-white h-10 w-20 px-6 py-2 rounded-md"
                onClick={() => {
                  confirmLogout();
                  toggleMobileMenu();
                }}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 px-6 py-2 h-10 w-20 rounded-md"
                onClick={() => {
                  cancelLogout();
                  toggleMobileMenu();
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
