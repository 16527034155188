import React, { useEffect, useState } from "react";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import {
  calculateGasEstimation,
  calculateGasPriceEstimation,
} from "../../metamask/helpers/gasEstimate";
import { getWalletData } from "../../utils/walletData";
import loading from "../../assets/images/loading.gif";

interface WriteAllFunctionsProps {
  contractAbi: any[];
  contractAddress: string;
}

const WriteAllFunctions: React.FC<WriteAllFunctionsProps> = ({
  contractAbi,
  contractAddress,
}) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [contract, setContract] = useState<any | null>(null);
  const [functions, setFunctions] = useState<any[]>([]);
  const [functionInputs, setFunctionInputs] = useState<{
    [key: string]: { name: string; type: string; value: string }[];
  }>({});
  const [transactionHash, setTransactionHash] = useState<string | null>(null);
  const [walletData, setWalletData] = useState(getWalletData());

  const [processingPopup, setProcessingPopup] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentProcessingFunction, setCurrentProcessingFunction] = useState<
    string | null
  >(null);

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        setWalletData(getWalletData());

        const web3Instance = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          setWeb3(web3Instance);
          const contractInstance = new web3Instance.eth.Contract(
            contractAbi,
            contractAddress
          );
          setContract(contractInstance);
        } catch (error) {
          console.error("Error enabling Ethereum:", error);
        }
      } else {
        console.error("Web3 not found. Please install MetaMask.");
      }
    };

    initWeb3();
  }, [contractAbi, contractAddress]);

  useEffect(() => {
    if (contract) {
      const contractMethods = contractAbi.filter(
        (func: any) =>
          (func.stateMutability === "nonpayable" ||
            func.stateMutability === "payable") &&
          func.name != null
      );
      setFunctions(contractMethods);

      const inputs: {
        [key: string]: { name: string; type: string; value: string }[];
      } = {};
      contractAbi.forEach((func: any) => {
        if (func.inputs && func.inputs.length > 0) {
          inputs[func.name] = func.inputs.map((input: any) => ({
            name: input.name,
            type: input.type,
            value: "",
          }));
        }
      });

      setFunctionInputs(inputs);
    }
  }, [contract]);

  const handleInputChange = (
    funcName: string,
    inputName: string,
    value: string
  ) => {
    setFunctionInputs((prevInputs) => ({
      ...prevInputs,
      [funcName]: prevInputs[funcName].map((input) =>
        input.name === inputName ? { ...input, value } : input
      ),
    }));
  };

  const executeFunction = async (funcName: string) => {
    try {
      setCurrentProcessingFunction(funcName);
      setProcessingPopup(true);

      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];
      if (functionInputs[funcName]) {
        const inputValues = functionInputs[funcName].map(
          (input) => input.value
        );

        // Check if there are inputs for the function
        if (inputValues.some((value) => value.trim() !== "")) {
          const typedInputValues = inputValues.map((value, index) => {
            const currentFunction = functions.find(
              (func) => func.name === funcName
            );

            if (
              !currentFunction ||
              !currentFunction.inputs ||
              !currentFunction.inputs[index]
            ) {
              console.error(
                `Error finding input information for function ${funcName}`
              );
              return value;
            }

            const inputType = currentFunction.inputs[index].type;

            switch (inputType) {
              case "address":
                return value;
              case "string":
                return value.toString();
              case "bool":
                return value.toLowerCase() === "true";
              case "uint64":
                return Number(value);
              case "uint256":
                return new BigNumber(value).toString(10);
              default:
                return new BigNumber(value);
            }
          });

          const gasEstimation = await calculateGasEstimation(
            contract,
            funcName,
            typedInputValues,
            currentAccount
          );
          const gasPriceEstimation = await calculateGasPriceEstimation(web3);

          const encodedABI = contract.methods[funcName](
            ...typedInputValues
          ).encodeABI();

          const tx = await web3.eth.sendTransaction({
            from: currentAccount,
            to: contractAddress,
            gas: gasEstimation,
            gasPrice: gasPriceEstimation,
            data: encodedABI,
          });
          setTransactionHash(web3.utils.bytesToHex(tx.transactionHash));
          setProcessingPopup(false);
          setSuccessPopup(true);
          setCurrentProcessingFunction(null);
        }
      } else {
        const gasEstimation = await calculateGasEstimation(
          contract,
          funcName,
          [],
          currentAccount
        );
        const gasPriceEstimation = await calculateGasPriceEstimation(web3);

        const encodedABI = contract.methods[funcName]().encodeABI();

        const tx = await web3.eth.sendTransaction({
          from: currentAccount,
          to: contractAddress,
          gas: gasEstimation,
          gasPrice: gasPriceEstimation,
          data: encodedABI,
        });

        const transactionHashString = web3.utils.bytesToHex(tx.transactionHash);
        setTransactionHash(transactionHashString);
        setProcessingPopup(false);
        setSuccessPopup(true);
        setCurrentProcessingFunction(null);
      }
    } catch (error: any) {
      setProcessingPopup(false);
      if (error.data) {
        setErrorMessage(
          `Error executing function ${funcName}  ${error.data.message}`
        );
      } else {
        setErrorMessage(
          `Error executing function ${funcName}: ${error.message}`
        );
      }
      setErrorPopup(true);
      console.error(`Error executing function ${funcName}:`, error);
      setCurrentProcessingFunction(null);
    }
  };

  const toCamelCase = (name: string) => {
    const fname = name.charAt(0).toUpperCase() + name.slice(1);
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <div className="mx-auto p-4 mt-2">
      <h2 className="text-2xl font-bold text-center mb-4">Write Functions</h2>
      {functions.map((func: any, index: number) => (
        <div
          key={index}
          className="mb-10 shadow-lg rounded-lg py-6 px-8 bg-gray-100 "
        >
          <p className="text-sm sm:text-md md:text-lg font-bold">{`${index + 1}. ${toCamelCase(
            func.name
          )}`}</p>
          {func.inputs && func.inputs.length > 0 ? (
            <div>
              {func.inputs.map((input: any, inputIndex: number) => (
                <div key={inputIndex} className="mb-3">
                  <label
                    htmlFor={`${func.name}_${input.name}`}
                    className="block text-xs sm:text-sm md:text-md lg:text-md font-medium text-gray-700"
                  >
                    {`${input.name} (${input.type}):`}
                  </label>
                  <input
                    type="text"
                    id={`${func.name}_${input.name}`}
                    placeholder={`Enter ${input.name}`}
                    value={functionInputs[func.name][inputIndex].value}
                    onChange={(e) =>
                      handleInputChange(func.name, input.name, e.target.value)
                    }
                    className="mt-1 p-1 border rounded-md w-full sm:w-full md:w-1/2"
                  />
                </div>
              ))}
              <button
                onClick={() => executeFunction(func.name)}
                className="flex text-sm px-4 sm:text-sm md:text-md mt-4 item-center sm:p-2 rounded-md hover:bg-green-600 cursor-pointer"
                style={{ backgroundColor: "#375BD2", color: "white" }}
              >
                Execute Function
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => executeFunction(func.name)}
                className="flex text-sm px-4 sm:text-sm md:text-md item-center mt-4 sm:p-2 rounded-md hover:bg-green-600 cursor-pointer"
                style={{ backgroundColor: "#375BD2", color: "white" }}
              >
                Execute Function
              </button>
            </div>
          )}
        </div>
      ))}

      <div>
        {/* Processing Popup */}
        {processingPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
              <div className="text-center mb-4">
                <p className="text-lg font-bold">Processing...</p>
              </div>
              <p className="text-base font-medium">
                {`Transaction for function: ${currentProcessingFunction} is in progress.`}
              </p>
              <img src={loading} alt="Loading" className="mx-auto mb-2 h-6" />
            </div>
          </div>
        )}

        {/* Success Popup */}
        {successPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 w-60 sm:w-80 md:w-96 lg:w-96 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
              <div className="text-center my-4">
                <p className="text-lg font-bold text-green-800">
                  Transaction successful!
                </p>
                <p className="mt-6">{`Your Transaction is complete now!`}</p>
                <p>Transaction Hash: {transactionHash}</p>
                <button
                  onClick={() => setSuccessPopup(false)}
                  className="mt-6 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 cursor-pointer"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Error Popup */}
        {errorPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
              <div className="text-center my-4">
                <p className="text-lg font-bold text-red-800">
                  Error during transaction.
                </p>
                <div className="mt-6 mb-4"> {errorMessage}</div>
                <button
                  onClick={() => setErrorPopup(false)}
                  className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 cursor-pointer"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WriteAllFunctions;
