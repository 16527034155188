import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ContractFormProps {
  account: string;
}

const ContractForm: React.FC<ContractFormProps> = ({ account }) => {
  const [formData, setFormData] = useState({
    projectName: "",
    contractName: "",
    contractAddress: "",
    contractABI: "",
    chainId: "",
  });
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");

  const API_URL = process.env.React_App_Backend_URL;

  const [submittedData, setSubmittedData] = useState(null);
  const [contracts, setContracts] = useState([]);
  // Inside your component

  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const token = sessionStorage.getItem("token"); // Get the token from session storage

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    // For 'selectedNetwork', update the state directly
    if (name === "selectedNetwork") {
      setSelectedNetwork(value);
    } else {
      // For other form fields, update the 'formData' state
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchContracts = async () => {
    try {
      const response = await axios.get(`${API_URL}contracts/getContracts`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });

      setContracts(response.data);
    } catch (error) {
      console.error("Error fetching contracts:", error);
      setErrorMessage(`Error fetching contracts or Seesion has expired please login agian: ${error}`);
      setErrorPopup(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let parsedABI;
    try {
      parsedABI = JSON.parse(formData.contractABI);
    } catch (error) {
      console.error("Error parsing contractABI as JSON:", error);

      try {
        parsedABI = JSON.stringify(formData.contractABI);
      } catch (conversionError) {
        console.warn("Error converting contractABI to JSON:", conversionError);
        return;
      }
    }

    try {
      const response = await axios.post(
        `${API_URL}contracts/addContract`,
        {
          ...formData,
          chainId: selectedNetwork,
          contractABI: parsedABI,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSubmittedData(formData);
      toast.success("Form submitted successfully!");

      setFormData({
        projectName: "",
        contractName: "",
        contractAddress: "",
        contractABI: "",
        chainId: "",
      });

      fetchContracts();
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    fetchContracts();
  }, [token]);
  return (
    <div className="overflow-x-auto sm:py-6 sm:px-6 sm:mx-4 rounded-2xl shadow-2xl bg-white">
      <form
        onSubmit={handleSubmit}
        className="max-w-full mx-auto mt-2 p-1 md:p-20"
      >
        <div className="overflow-x-auto ml-4 px-8 md:px-20 py-10 shadow-lg rounded-lg bg-white ">
          <h2
            className="sm:text-lg md:text-xl xl:text-2xl font-bold text-center mb-4"
            style={{ color: "#375BD2" }}
          >
            Add Pool Details
          </h2>

          <div className="mb-4">
            <label
              htmlFor="contractName"
              className="block text-gray-700 text-xs sm:text-sm md:text-md font-bold mb-2"
            >
              Project Name:
            </label>
            <input
              type="text"
              id="projectName"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              className="w-full sm:px-3 sm:py-2 border border-black rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="contractName"
              className="block text-gray-700 text-xs sm:text-sm md:text-md font-bold mb-2"
            >
              Contract Name:
            </label>
            <input
              type="text"
              id="contractName"
              name="contractName"
              value={formData.contractName}
              onChange={handleChange}
              className="w-full sm:px-3 sm:py-2 border border-black rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="selectedNetwork"
              className="block text-gray-700 text-xs sm:text-sm md:text-md font-bold mb-2"
            >
              Select Network:
            </label>
            <select
              id="selectedNetwork"
              name="selectedNetwork"
              value={selectedNetwork}
              onChange={handleChange}
              className="w-full sm:px-3 sm:py-2 border border-black rounded focus:outline-none focus:border-blue-500"
            >
              <option value="">Select a Network</option>
              <option value="56">BSC Scan Mainnet</option>
              <option value="97">BSC Scan Testnet </option>
              <option value="137">Polygon Mainnet</option>
              <option value="80001">Mumbai Polygon Testnet </option>
              <option value="1"> Ether Mainnet</option>
              <option value="5">EtherScan Goerli</option>
              <option value="11155111">EtherScan Sepolia</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="contractAddress"
              className="block text-gray-700 text-xs sm:text-sm md:text-md font-bold mb-2"
            >
              Contract Address:
            </label>
            <input
              type="text"
              id="contractAddress"
              name="contractAddress"
              value={formData.contractAddress}
              onChange={handleChange}
              className="w-full sm:px-3 sm:py-2 border border-black rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="contractABI"
              className="block text-gray-700 text-xs sm:text-sm md:text-md font-bold mb-2"
            >
              Contract ABI:
            </label>
            <textarea
              id="contractABI"
              name="contractABI"
              value={formData.contractABI}
              onChange={handleChange}
              className="w-full px-2 sm:px-3 sm:py-2 border border-black rounded focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-500 text-white text-xs sm:text-sm md:text-md px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green"
              style={{ backgroundColor: "#375BD2" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <ToastContainer
        style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        autoClose={5000}
      />

      <div>
        {contracts.length > 0 && (
          <div className="max-w-full mx-auto p-1 mx-2 md:pt-8 md:px-16 md:pb-16 ">
            <h2
            className="sm:text-lg md:text-xl xl:text-2xl font-bold text-center mb-4"
            style={{ color: "#375BD2" }}
            >
              Contract List
            </h2>
            <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
              <table className="table-auto min-w-full border-collapse border">
                <thead>
                  <tr
                    className="mb-4"
                    style={{ backgroundColor: "#375BD2", color: "white" }}
                  >
                    <th className="px-1 py-2 md:py-4 border text-xs sm:text-sm md:text-md ">
                      Project Name
                    </th>
                    <th className="px-1 py-2 md:py-4 border text-xs sm:text-sm md:text-md">
                      Contract Name
                    </th>
                    <th className="px-1 py-2 md:py-4 border text-xs sm:text-sm md:text-md ">
                      Contract Address
                    </th>
                  </tr>
                </thead>
                <tbody className="flex-col bg-white item-center">
                  {contracts.map((contract) => (
                    <tr key={contract._id} className="hover:bg-gray-100 ">
                      <td className="pl-10 text-xs sm:text-sm md:text-md pr-4 py-1 border">
                        {contract.projectName}
                      </td>
                      <td className="px-2 py-1 text-xs sm:text-sm md:text-md border">
                        {contract.contractName}
                      </td>
                      <td className="pl-2 py-1 text-xs sm:text-sm md:text-md border">
                        {contract.contractAddress}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {errorPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
            <div className="text-center my-4">
              <p className="text-lg font-bold text-red-800">Error</p>
              <div className="mt-6 mb-4">{errorMessage}</div>
              <button
                onClick={() => setErrorPopup(false)}
                className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 cursor-pointer"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractForm;
