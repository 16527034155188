import React, { useState } from "react";

interface FAQSection {
  question: string;
  answer: string | FAQSubsection[];
  open?: boolean;
}

interface FAQSubsection {
  subtitle: string;
  description: string | string[];
}

const FAQComponent: React.FC = () => {
  const [faqs, setFaqs] = useState<FAQSection[]>([
    {
      question: "What is a Dapp?",
      answer:
        "A decentralized application (Dapp) is an innovative application that operates on a blockchain network, leveraging smart contracts to facilitate trustless and transparent interactions. In our Dapp, users can engage with smart contracts across various networks, including Binance Smart Chain (BSC), Polygon, Ethereum, Sepholia, and Goierly.",
      open: true,
    },
    {
      question: "About the Application",
      answer: [
        {
          subtitle: "Seamless Interaction",
          description:
            "With our web application, users can seamlessly interact with smart contracts, execute transactions, and explore various functionalities. This includes accessing read-only information, performing write functions, and experimenting with dynamic contract additions. The Dapp provides a user-friendly experience for both beginners and experienced users in the blockchain space.",
        },
        {
          subtitle: "Democratizing Access",
          description:
            "The application aims to democratize access to blockchain functionalities by offering a user-centric interface. Users can add and interact with contracts across multiple networks, enhancing their experience with decentralized applications. The emphasis on ease of use, rapid prototyping, and dynamic contract management distinguishes this project, providing users with a powerful tool for engaging with blockchain technology.",
        },
        {
          subtitle: "Key Features",
          description: [
            "Provides an environment for users to interact with blockchain smart contracts on multiple networks (BSC Mainnet, BSC Testnet, Polygon Mainnet, Polygon Mumbai Testnet, Ethereum Mainnet, Sepolia, Goerli).",
            "Users can add contracts by specifying details like project name, contract name, network, address, and ABI code.",
            "No contract verification is required; users can directly access functions and make transactions.",
            "Contract details are displayed upon submission.",
            'Transactions can be made from the "Projects" section by selecting a contract and choosing a read-only function or write function (with transaction execution).',
          ],
        },
      ],
      open: false,
    },
    {
      question: "How to Add a Contract?",
      answer: [
        {
          subtitle: "Go to Add Project Tab",
          description: "",
        },
        {
          subtitle: " Fill Contract Details in Form",
          description:
            "Complete the contract details form, providing project-specific information such as project name, contract name, network, address, and ABI code.",
        },
        {
          subtitle: "Submit Form",
          description:
            "Click on the submit button to process the provided contract details.",
        },
        {
          subtitle: "View Contract Details",
          description:
            "After submission, view the contract details in below add Contract form section of the application.",
        },
      ],
      open: false,
    },
    {
      question: "How to Interact with Smart Contract?",
      answer: [
        {
          subtitle: "Go to Projects Tab",
          description: "",
        },
        {
          subtitle: " Access the Projects tab on the Dapp interface.",
          description: "",
        },
        {
          subtitle: " Select Your Added Project",
          description: "Choose the project you added from the list.",
        },
        {
          subtitle: "Select Smart Contract to Communicate",
          description: " Pick the smart contract you want to interact with.",
        },
        {
          subtitle: "Read Section",
          description:
            "After submission, view the contract details in the Projects section of the application.Access read-only functions of the smart contract for information retrieval.",
        },
        {
          subtitle: "Write Section",
          description:
            "Access write functions to make transactions and modify the blockchain's state.",
        },
      ],
      open: false,
    },
    {
      question: "Advantages of Using This Application",
      answer: [
        {
          subtitle: " User-Friendly Experience",
          description:
            "The Dapp offers a straightforward and user-friendly experience, making it accessible for both beginners and experienced users.",
        },
        {
          subtitle: "Rapid Prototyping",
          description:
            "Developers and users can quickly prototype and test interactions without waiting for formal verification, fostering innovation.",
        },
        {
          subtitle: " Dynamic Contract Management",
          description:
            "Users can dynamically add, experiment, and switch between contracts seamlessly, tailoring their blockchain experience.",
        },
        {
          subtitle: "Reduced Entry Barriers",
          description:
            "Eliminates the need for formal contract verification, reducing entry barriers and simplifying onboarding for users.",
        },
      ],
      open: false,
    },
  ]);

  const toggleFAQ = (index: number) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : false,
      }))
    );
  };

  return (
    <div className="h-full w-full justify-center">
      <div className="max-w-full mx-auto bg-white px-2 rounded-2xl shadow-2xl py-4">
        <div className="faqs">
          <h1
            className="text-md flex flex-col  items-center sm:text-md md:text-md lg:text-lg mt-2 md:mt-6 mb-4 md:mb-12 xl:text-3xl font-bold mx-6 sm:mx-8 md:mx-12 lg:mx-16"
            style={{ color: "#375BD2" }}
          >
            Frequently Asked Questions (FAQs)
          </h1>
          {faqs.map((faq, i) => (
            <div
              className={`faq ${faq.open ? "open" : ""}`}
              key={i}
              onClick={() => toggleFAQ(i)}
            >
              <div className="faq-question font-semibold text-sm sm:text-md md:text-lg ">
                {faq.question}
              </div>
              <div className="faq-answer">
                {Array.isArray(faq.answer) ? (
                  faq.answer.map((subSection, j) => (
                    <div key={j}>
                      <h4 className="text-xs sm:text-md md:text-lg font-medium list-disc">
                        <div className="faq-subtitle m-2">
                          &#8226;  {subSection.subtitle} 
                        </div>
                      </h4>

                      <div className="faq-description text-xs sm:text-md md:text-lg list-disc pl-4 my-0.5 sm:my-2 ">
                        {Array.isArray(subSection.description) ? (
                          subSection.description.map((item, k) => (
                            <p className="m-1" key={k}>&#8226; {item}</p>
                          ))
                        ) : (
                          <p className="text-xs sm:text-md md:text-lg list-disc">
                            {" "}
                            {subSection.description}
                          </p>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-xs sm:text-md md:text-lg list-disc ">
                    {" "}
                    {faq.answer}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
