import React, { useEffect, useState } from "react";
import ReadAllFunctions from "./readAllFunctions";
import WriteAllFunctions from "./writeAllfunctions";
import loading from "../../assets/images/loading.gif";
import axios from "axios";
import {
  switchNetwork,
  getWalletData,
  setWalletData,
  subscribeToWalletDataChanges,
} from "../../utils/walletData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContractInteraction: React.FC = ({}) => {
  const [selectedTab, setSelectedTab] = useState<string>("read");
  const [contracts, setContracts] = useState([]);
  const [selectedContractKey, setSelectedContractKey] = useState<{
    contractAbi: any[];
    contractAddress: string | null;
  } | null>(null);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [networkSwitching, setNetworkSwitching] = useState<boolean>(false);
  const [userAccount, setUserAccount] = useState<string | null>(null);

  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const API_URL = process.env.React_App_Backend_URL;

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(`${API_URL}contracts/getContracts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setContracts(response.data);
        const accountData = getWalletData().account;
        setUserAccount(accountData);
      } catch (error) {
        console.error("Error fetching contracts:", error);
        setErrorMessage(
          `Error fetching contracts or Seesion has expired please login agian: ${error}`
        );
        setErrorPopup(true);
      }
    };

    const unsubscribe = subscribeToWalletDataChanges(() => {
      // Update userAccount when walletData changes
      const accountData = getWalletData().account;
      setUserAccount(accountData);
    });

    fetchContracts();
    return () => {
      unsubscribe();
    };
  }, [userAccount]);

  useEffect(() => {
    const accountData = getWalletData().account;
    if ((accountData === null || userAccount===null) && selectedContractKey) {
      toast.error("Connect your wallet to access this feature.", {
        position: "top-right",
        autoClose: 4000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [userAccount, selectedContractKey]);

  const handleContractSelection = async (key: string) => {
    const selectedContract = contracts.find((contract) => contract._id === key);

    if (selectedContract) {
      try {
        const contractAbiString = selectedContract.contractABI;
        let contractAbi;
        try {
          contractAbi = JSON.parse(contractAbiString);
        } catch (parseError) {
          console.error("Error parsing contract ABI as JSON:", parseError);
          try {
            contractAbi = JSON.stringify(contractAbiString);
          } catch (conversionError) {
            console.error(
              "Error converting contract ABI to JSON:",
              conversionError
            );
            setErrorMessage(
              `Error converting contract ABI to JSON : ${conversionError}`
            );
            setErrorPopup(true);
            setSelectedContractKey(null);
            return;
          }
        }
        const contractAddress = selectedContract.contractAddress;
        const chainId = selectedContract.chainId;

        const currentChainId = parseInt(getWalletData().chainId, 10);

        if (parseInt(chainId, 10) !== currentChainId) {
          setNetworkSwitching(true); // Set the network switching state to true

          try {
            await switchNetwork(parseInt(chainId, 10));
          } catch (error) {
            console.error("Error switching network:", error);
            setErrorMessage(`Error switching network : ${error}`);
            setErrorPopup(true);
          }

          setNetworkSwitching(false); // Set the network switching state back to false after completion
        }

        setSelectedContractKey({
          contractAbi: contractAbi,
          contractAddress: contractAddress,
        });
      } catch (error) {
        console.error("Error handling selected contract:", error);
        setErrorMessage(`Error handling selected contract: ${error}`);
        setErrorPopup(true);
        setSelectedContractKey(null); // Clear selectedContractKey on error
      }
    } else {
      setSelectedContractKey(null); // Clear selectedContractKey when unselecting
    }
  };
  const handleProjectSelection = (project: string) => {
    setSelectedProject(project);
  };

  const filteredContracts = selectedProject
    ? contracts.filter((contract) => contract.projectName === selectedProject)
    : contracts;

  return (
    <div className="max-w-full mx-auto bg-white rounded-2xl shadow-2xl py-10">
      <ToastContainer />

      <div className="bg-white mx-auto flex flex-col items-center px:10 py-4 ">
        <h1
          className="text-lg md:text-xl xl:text-2xl font-bold mb-8"
          style={{ color: "#375BD2" }}
        >
          Smart Contract functions
        </h1>
        <div className="flex items-center  text-sm  my-4">
          <label htmlFor="projectDropdown" className="mr-4 text-sm font-bold">
            Project:
          </label>
          <select
            id="projectDropdown"
            className="p-2 border border-blue-300 rounded w-40 sm:w-56 md:w-96"
            onChange={(e) => handleProjectSelection(e.target.value)}
          >
            <option value="">Select a Project</option>
            {[
              ...new Set(contracts.map((contract) => contract.projectName)),
            ].map((project) => (
              <option key={project} value={project}>
                {project}
              </option>
            ))}
          </select>
        </div>
        {selectedProject && (
          <div className="flex items-center text-sm my-4">
            <label htmlFor="projectDropdown" className="mr-2 text-sm font-bold">
              Contract:
            </label>
            <select
              id="contractDropdown"
              className="p-2 border border-blue-300 rounded w-40 sm:w-56 md:w-96"
              onChange={(e) => handleContractSelection(e.target.value)}
            >
              <option value="">Select a Contract</option>
              {filteredContracts.map((contract) => (
                <option key={contract._id} value={contract._id}>
                  {contract.contractName} : {contract.contractAddress}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="flex  my-6">
          {selectedContractKey && (
            <>
              <button
                className={`mr-4 py-2 px-2 sm:px-4 md:px-6 ${
                  selectedTab === "read"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-black"
                } rounded`}
                onClick={() => setSelectedTab("read")}
              >
                Read
              </button>
              <button
                className={`py-2 px-2 sm:px-4 md:px-6 ${
                  selectedTab === "write"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-black"
                } rounded`}
                onClick={() => setSelectedTab("write")}
              >
                Write
              </button>
            </>
          )}
        </div>
        <div className="w-full p-4">
          {networkSwitching ? (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
                <div className="text-center mb-4">
                  <p className="text-lg font-bold">Switching Network...</p>
                </div>
                <img src={loading} alt="Loading" className="mx-auto mb-2 h-6" />
              </div>
            </div>
          ) : (
            <>
              {selectedTab === "read" && selectedContractKey && (
                <ReadAllFunctions
                  contractAbi={selectedContractKey.contractAbi}
                  contractAddress={selectedContractKey.contractAddress}
                />
              )}

              {selectedTab === "write" && selectedContractKey && (
                <WriteAllFunctions
                  contractAbi={selectedContractKey.contractAbi}
                  contractAddress={selectedContractKey.contractAddress}
                />
              )}
            </>
          )}
        </div>
        {errorPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="popup fixed w-60 sm:w-80 md:w-96 lg:w-96 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md shadow-lg backdrop-filter backdrop-blur-md">
              <div className="text-center my-4">
                <p className="text-lg font-bold text-red-800">Error</p>
                <div className="mt-6 mb-4"> {errorMessage}</div>
                <button
                  onClick={() => setErrorPopup(false)}
                  className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 cursor-pointer"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractInteraction;
