import { BigNumber } from 'bignumber.js';  // Import BigNumber if not already imported
import Web3 from 'web3';

export async function calculateGasEstimation(
  contract: any,
  methodName: string,
  methodParams: any[],
  currentAccount: string
): Promise<number> {
  try {
    const method = (contract as any).methods[methodName];

    const typedMethodParams = methodParams.map(param => {
      if (typeof param === 'string' && param.startsWith('"') && param.endsWith('"')) {
        return param.slice(1, -1);
      }
      return param;
    });

    const formattedMethodParams = typedMethodParams.map(param => {
      if (typeof param === 'string' && !param.startsWith('0x') && !isNaN(Number(param))) {
        return new BigNumber(param).toString(10);
      }
      return param;
    });

    const gasEstimateBigInt = await method(...formattedMethodParams).estimateGas({ from: currentAccount });
    
    const gasEstimate = Number(gasEstimateBigInt);
    
    return gasEstimate;
  } catch (error) {
    console.error(`Error estimating gas for method ${methodName}:`, error);
    throw error;
  }
}

export async function calculateGasPriceEstimation(web3: Web3): Promise<number> {
  try {
    const gasPriceBigInt = await web3.eth.getGasPrice();
    
    const gasPrice = Number(gasPriceBigInt);
    return gasPrice;
  } catch (error) {
    console.error("Error getting gas price:", error);
    throw error;
  }
}