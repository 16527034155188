import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/logo.svg";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

interface SignupFormProps {
  onSignup: (message: string) => void;
  onLogin: (token: string, role: string) => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSignup, onLogin }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    const API_URL = process.env.React_App_Backend_URL;
    try {
      const response = await axios.post(`${API_URL}user/register`, {
        username,
        email,
        password,
      });

      const data = response.data;
      onSignup(data.message);
      navigate("/login");

      // Handle registration error
      console.error(response.data.message);
    } catch (error: any) {
      console.error("Something went wrong ", error);
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 10000);
      } else {
        setErrorMessage("An unexpected error occurred");
      }
    }
  };

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  useEffect(() => {
    const loadGapi = () => {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            clientId: clientId,
          })         
          .catch((error: any) => {
            console.error("Error initializing Google API client", error);
          });
      });
    };

    loadGapi();
  }, [clientId]);

  const handleGoogleLogin = async (googleData: any) => {
    try {
      const API_URL = process.env.React_App_Backend_URL;
      const response = await axios.post(`${API_URL}user/google/callback`, {
        googleData: googleData,
      });

      const data = response.data;
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("user", data.user);
      sessionStorage.setItem("role", data.user.role);

      onLogin(data.token, data.role);

      sessionStorage.setItem("authenticated", "true");
      navigate("/mainview");
    } catch (error: any) {
      console.error("Error communicating with the backend:", error);
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 10000);
      } else {
        console.error("An error occurred during Google login ", error);
        setErrorMessage("An error occurred during Google login");
      }
    }
  };
  const handleFailure = (result: any) => {
    console.error(result);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-50">
      <div className="bg-white p-8 rounded-lg shadow-md sm:w-96 md:w-120 w-full">
        <div className="mb-4 text-center">
          <a href="https://www.blocsys.com/" rel="noopener noreferrer">
            <img src={logo} alt="Logo" className="mb-8 h-14 w-48 mx-auto" />
          </a>
          <h3 className="text-xl font-bold">Sign Up</h3>
        </div>
        <form onSubmit={handleSignup} className="space-y-4">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-600"
            >
              Username:
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={`mt-1 p-3 border ${
                errorMessage ? "border-red-500" : "border-gray-300"
              } rounded-md w-full focus:outline-none focus:ring focus:border-blue-300`}
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`mt-1 p-3 border ${
                errorMessage ? "border-red-500" : "border-gray-300"
              } rounded-md w-full focus:outline-none focus:ring focus:border-blue-300`}
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Password:
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`mt-1 p-3 border ${
                errorMessage ? "border-red-500" : "border-gray-300"
              } rounded-md w-full focus:outline-none focus:ring focus:border-blue-300`}
            />
          </div>
          {errorMessage && (
            <p className="text-red-500 text-center">{errorMessage}</p>
          )}
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white py-3 px-8 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              style={{ background: "#375BD2" }}
            >
              Signup
            </button>
          </div>

          <p className="mt-4 text-center">
            Already have an account?{" "}
            <a
              href="/login"
              className="text-blue-500 hover:underline focus:outline-none"
              style={{ color: "#375BD2" }}
            >
              Login here
            </a>
          </p>

          <div className="mt-4 text-center flex items-center justify-center space-x-4">
            <hr className="flex-grow border-t border-gray-500" />
            <span> OR </span>
            <hr className="flex-grow border-t border-gray-500" />
          </div>
          
          <div className="mt-4 text-center">
            <GoogleLogin
              clientId= {process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
              buttonText="Continue with Google"
              onSuccess={handleGoogleLogin}
              onFailure={handleFailure}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
