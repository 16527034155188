import React from "react";

interface DisconnectModalProps {
  onCancel: () => void;
  onDisconnect: () => void;
  userAddress: string; // Add userAddress prop
}

const DisconnectModal: React.FC<DisconnectModalProps> = ({
  onCancel,
  onDisconnect,
  userAddress,
}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-200 p-4 rounded-md ">
        <p className="text-black font-bold my-8 mx-10 ">
          Are you sure you want to disconnect your wallet?
        </p>      
          <div className="text-center text-black mt-4 mb-8 ">
            <span className="text-50 font-bold mx-1 my-5 "> Wallet Address: </span>
            <span className="text-500 font-bold mr-1">
              {userAddress.slice(0, 6)}
            </span>
            <span className="text-50 font-bold">...</span>
            <span className="text-500 font-bold ml-1">
              {userAddress.slice(-6)}
            </span>
          </div>

        <div
          className="flex justify-center mt-4"
          style={{ display: "flex", alignItems: "center" }}
        >
          <button
            className=" mr-8 bg-red-500 text-white px-6 py-2 rounded-md"
            onClick={onDisconnect}
          >
            Disconnect
          </button>
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded-md"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisconnectModal;
