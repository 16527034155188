import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Views/Navbar";
import Footer from "./Footer/footer";

const MainView: React.FC = () => {
  const [userAccount, setUserAccount] = useState<string | null>(null);

  return (
    <div className="items-center h-full w-full bg-blue-50 rounded-lg shadow-lg">
      <Navbar setUserAccount={setUserAccount} />

      <div className="h-full w-full p-4  mt-4  sm:mt-6  md:mt-6 lg:mt-6 xl:mt-8 sm:p-6 md:p-8 lg:p-16 xl:p-18 justify-center">
        <Outlet />
      </div>
      <div className="bg-white rounded-2xl shadow-2xl">
        <Footer />
      </div>
    </div>
  );
};

export default MainView;
