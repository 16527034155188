import React, { useEffect } from "react";

import classes from "./footer.module.css"; // Assuming you have the CSS module
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import LinkedInIcon from "../../assets/images/LinkedInIcon.svg";
import telegramIMG from "../../assets/images/telegramIMG.svg";
import xIcon from "../../assets/images/xIcon.svg";
interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  useEffect(() => {
    // Change the URL in the address bar to the desired format
    window.history.replaceState({}, "", "/");
  }, []);

  return (
    <div>
      <footer className={classes.footer}>
        <div className={classes.clutchmain}>
          <Link to="https://www.blocsys.com/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={classes.quickLinks}>
          <h2 className="my-4 mx-2">QUICK LINKS</h2>
          <div className="md:mx-4">
            <ul>
              <li>
                <a href="https://www.blocsys.com/about-us">About Us</a>
              </li>
              <li>
                <a href="https://www.blocsys.com/contact-us">Contact Us</a>
              </li>
              <li>
                <a href="https://www.blocsys.com/Web3-Technologies">
                  Technologies
                </a>
              </li>
              <li>
                <a href="https://www.blocsys.com/">Privacy Policy</a>
              </li>
              <li>
                <a href="https://www.blocsys.com/CookiePolicy">Cookie Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.footerAddress}>
          <h2 className="my-4 mx-2">ADDRESS</h2>
          <div className="mx-2 md:mx-4">
            <ul>
              <li>
                S-73, Destination Centre-I, Nanded City, <br />
                Singhgad Road, Pune, India, 411041
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.footerContact}>
          <h2 className="my-4 mx-2">Contact</h2>
          <div className="mx-2 md:mx-4">
            <ul>
              <li>info@blocsys.com</li>
            </ul>
          </div>
        </div>
        <div className={classes.socialLinks}>
          <h2 className=" mx-4 my-4">SOCIAL LINKS</h2>
          <div className="mx-2 md:mx-4">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/blocsys/"
                  target="_blank"
                  rel="noopener"
                >
                  <img src={LinkedInIcon} alt="LinkedInIcon" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/blocsys"
                  target="_blank"
                  rel="noopener"
                >
                  <img className="h-12 w-16 sm:h-10  md:h-10 lg:h-8 xl:h-10" src={xIcon} alt="TwitterIcon" />
                </a>
              </li>
              <li>
                <a href="https://t.me/KumarC5" target="_blank" rel="noopener">
                  <img src={telegramIMG} alt="telegramIMG" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className={classes.footerbottom}>
        <h4>© Blocsys, All Rights Reserved.</h4>
      </div>
    </div>
  );
};

export default Footer;
