import React, { useState, useEffect } from "react";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { getWalletData } from "../../utils/walletData"; // Import the wallet data functions

interface ReadAllFunctionsProps {
  contractAbi: any[];
  contractAddress: string;
}

const ReadAllFunctions: React.FC<ReadAllFunctionsProps> = ({
  contractAbi,
  contractAddress,
}) => {
  const [contract, setContract] = useState<any | null>(null);
  const [readFunctions, setReadFunctions] = useState<any[]>([]);
  const [readData, setReadData] = useState<{ [key: string]: any }>({});
  const [readResponses, setReadResponses] = useState<any[]>([]);
  const [functionInputs, setFunctionInputs] = useState<{
    [key: string]: { name: string; type: string; value: string }[];
  }>({});
  const [executedFunction, setExecutedFunction] = useState<string | null>(null);
  const [executedFunctionResponse, setExecutedFunctionResponse] = useState<
    any | null
  >(null);
  const [walletData, setWalletData] = useState(getWalletData()); // Initialize with the initial wallet data

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          const contractInstance = new web3Instance.eth.Contract(
            contractAbi,
            contractAddress
          );
          setContract(contractInstance);
          setWalletData(getWalletData());
        } catch (error) {
          console.error("Error enabling Ethereum:", error);
        }
      } else {
        console.error("Web3 not found. Please install MetaMask.");
      }
    };

    initWeb3();
  }, [contractAbi, contractAddress]);

  useEffect(() => {
    if (contract) {
      const allReadFunctions = contractAbi.filter(
        (func: any) =>
          func.stateMutability === "view" || func.stateMutability === "pure"
      );

      setReadFunctions(allReadFunctions);

      const inputs: {
        [key: string]: { name: string; type: string; value: string }[];
      } = {};
      contractAbi.forEach((func: any) => {
        if (func.inputs && func.inputs.length > 0) {
          inputs[func.name] = func.inputs.map((input: any) => ({
            name: input.name,
            type: input.type,
            value: "",
          }));
        }
      });
      setFunctionInputs(inputs);
    }
  }, [contractAbi, contract]);

  const handleInputChange = (
    funcName: string,
    inputName: string,
    value: string
  ) => {
    setFunctionInputs((prevInputs) => ({
      ...prevInputs,
      [funcName]: prevInputs[funcName].map((input) =>
        input.name === inputName ? { ...input, value } : input
      ),
    }));
  };

  const fetchDataForReadFunctions = async () => {
    try {
      const web3 = new Web3(walletData.rpcUrl);
      const contract = new web3.eth.Contract(contractAbi, contractAddress);

      const fetchPromises = readFunctions.map(async (func) => {
        try {
          let result;
          if (func.inputs.length === 0) {
            // Call the read method (constant function) without parameters
            result = await contract.methods[func.name]().call();
          } else {
            // Call the read method (constant function) with parameters
            const inputValues = functionInputs[func.name].map(
              (input) => input.value
            );
            result = await executeWithInputs(
              contract,
              func.name,
              func.inputs,
              inputValues
            );
          }

          // Convert BigInt values to strings
          const resultWithStrings = convertBigIntToString(result);

          // Update the readData state with the fetched data for the current function
          setReadData((prevReadData) => ({
            ...prevReadData,
            [func.name]: resultWithStrings,
          }));

          // Also update the readResponses state with the function name and result
          setReadResponses((prevReadResponses) => [
            ...prevReadResponses,
            { funcName: func.name, result: resultWithStrings },
          ]);
        } catch (error: any) {
          console.error(`Error fetching data for ${func.name}:`, error.message);
        }
      });

      await Promise.all(fetchPromises);
    } catch (error: any) {
      console.error("Error fetching data for read functions:", error.message);
    }
  };

  const executeWithInputs = async (
    contract: any,
    funcName: string,
    inputs: any[],
    values: any[]
  ) => {
    const typedInputValues = values.map((value, index) => {
      const inputType = inputs[index].type;
      switch (inputType) {
        case "address":
          return value;
        case "string":
          return value.toString();
        case "bool":
          return value.toLowerCase() === "true";
        case "uint64":
          return Number(value);
        case "uint256":
          return new BigNumber(value).toString();
        default:
          return new BigNumber(value);
      }
    });

    return await contract.methods[funcName](...typedInputValues).call();
  };

  const convertBigIntToString = (obj: any): any => {
    if (typeof obj === "bigint") {
      return obj.toString();
    } else if (Array.isArray(obj)) {
      return obj.map(convertBigIntToString);
    } else if (typeof obj === "object" && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          convertBigIntToString(value),
        ])
      );
    }
    return obj;
  };

  const executeFunction = async (funcName: string) => {
    try {
      const inputValues = functionInputs[funcName].map((input) => input.value);

      const typedInputValues = inputValues.map((value, index) => {
        const currentFunction = readFunctions.find(
          (func) => func.name === funcName
        );

        const inputType = currentFunction.inputs[index].type;

        switch (inputType) {
          case "address":
            return value;
          case "string":
            return value.toString();
          case "bool":
            return value.toLowerCase() === "true";
          case "uint64":
            return Number(value);
          case "uint256":
            return new BigNumber(value).toString();
          default:
            return new BigNumber(value);
        }
      });

      const response = await contract.methods[funcName](
        ...typedInputValues
      ).call();

      setExecutedFunction(funcName);
      setExecutedFunctionResponse(convertBigIntToString(response));
    } catch (error) {
      console.error(`Error executing function ${funcName}:`, error);
    }
  };

  const renderFunctions = () => {
    return readFunctions.map((func: any, index: any) => (
      <div
        key={index}
        className="mb-6 shadow-lg rounded-lg py-6 px-8 bg-gray-100"
        style={{ overflowWrap: "break-word" }}

      >
        <p className="text-sm sm:text-md md:text-lg font-bold ">{`${index + 1}. ${toCamelCase(
          func.name
        )}`}</p>
        {func.inputs.length > 0 && (
          <div>
            {func.inputs.map((input: any, inputIndex: number) => (
              <div key={inputIndex} className="mb-3 ">
                <label
                  htmlFor={`${func.name}_${input.name}`}
                  className="block text-xs sm:text-sm md:text-md lg:text-md font-medium text-gray-700"
                >
                  {`${input.name} (${input.type}):`}
                </label>
                <input
                  type="text"
                  id={`${func.name}_${input.name}`}
                  placeholder={`Enter ${input.name}`}
                  value={functionInputs[func.name][inputIndex].value}
                  onChange={(e) =>
                    handleInputChange(func.name, input.name, e.target.value)
                  }
                  className="mt-1 p-1 border rounded-md w-full sm:w-full md:w-1/2"
                />
              </div>
            ))}
            <button
              onClick={() => executeFunction(func.name)}
              className="flex text-sm px-4 sm:text-sm md:text-md item-center  sm:p-2 rounded-md hover:bg-green-600 cursor-pointer "
              style={{ backgroundColor: "#375BD2", color: "white" }}
            >
              Execute Function
            </button>
          </div>
        )}

        {readData[func.name] !== undefined && (
          <div className="mt-3">
            <div>{formatReadData(readData[func.name])}</div>
          </div>
        )}

        {renderExecutedFunctionResponse(func.name)}
      </div>
    ));
  };

const formatReadData = (data: any) => {
  const replacer = (key: any, value: any) => {
    if (typeof value === "bigint") {
      return value.toString();
    } else if (typeof value === "boolean") {
      return value ? "true" : "false";
    }
    return value;
  };

  if (typeof data === "object" && !Array.isArray(data)) {
    return Object.entries(data)
      .map(([key, value]) => `${key}: ${formatValue(value, replacer)}\n`)
      .join("");
  } else {
    return formatValue(data, replacer) + "\n"; // Ensure newline after primitive values
  }
};

const formatValue = (value: any, replacer: Function, topLevel: boolean = true): string => {
  if (typeof value === "object" && !Array.isArray(value)) {
    return `{${Object.entries(value)
      .map(([key, val]) => `${topLevel ? "" : "\n"}"${key}": ${formatValue(val, replacer, false)}`)
      .join(", ")}}`;
  } else if (Array.isArray(value)) {
    return `[${value.map((val) => formatValue(val, replacer, false)).join(", ")}]`;
  } else {
    return replacer(null, value);
  }
};


  const toCamelCase = (name: string) => {
    const fname = name.charAt(0).toUpperCase() + name.slice(1);
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const renderExecutedFunctionResponse = (funcName: string) => {
    // Render executed function response only if it matches the current function
    if (executedFunction === funcName && executedFunctionResponse !== null) {
      return (
        <div className="mb-4">
          <p className="text-lg font-bold">{`${toCamelCase(funcName)} : `}</p>
          <div>{formatReadData(executedFunctionResponse)}</div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (readFunctions.length > 0) {
      fetchDataForReadFunctions();
      const walletData = getWalletData();
    }
  }, [readFunctions]);

  const replacer = (key: any, value: any) => {
    if (typeof value === "bigint") {
      return value.toString();
    }
    return value;
  };

  return (
    <div className="mx-auto p-4">
      <div className="mt-2">
        <h2 className="text-2xl font-bold text-center mb-4">Read Functions</h2>
        {renderFunctions()}
      </div>
    </div>
  );
};

export default ReadAllFunctions;
