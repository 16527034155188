let walletData: {
  account: string | null;
  chainId: string | null;
  rpcUrl: string | null;
} = {
  account: null,
  chainId: null,
  rpcUrl: null,
};
const subscribers: (() => void)[] = [];

export const setWalletData = (
  account: string | null,
  chainId: string | null
) => {
  const rpcUrl = calculateRpcUrlFromChainId(chainId || "");
  walletData = { account, chainId, rpcUrl };
  notifySubscribers();

};

export const getWalletData = () => {
  return { ...walletData };
};


const notifySubscribers = () => {
  subscribers.forEach((subscriber) => subscriber());
};

export const subscribeToWalletDataChanges = (subscriber: () => void) => {
  subscribers.push(subscriber);

  // Return a function to unsubscribe
  return () => {
    const index = subscribers.indexOf(subscriber);
    if (index !== -1) {
      subscribers.splice(index, 1);
    }
  };
}; 

const rpcUrlConfig: { [key: string]: string } = {
    "56": process.env.REACT_APP_BSCMianRPC_URL || "",
    "0x38": process.env.REACT_APP_BSCMianRPC_URL || "",
    "97": process.env.REACT_APP_RPC_URL || "",
    "0x61": process.env.REACT_APP_RPC_URL || "",
    "137": process.env.REACT_APP_Polygon_Main_netRPC_URL || "",
    "0x89": process.env.REACT_APP_Polygon_Main_netRPC_URL || "",
    "80001": process.env.REACT_APP_Polygon_RPC_URL || "",
    "0x13881": process.env.REACT_APP_Polygon_RPC_URL || "",
    "1": process.env.REACT_APP_EtherScanMian_RPC_URL || "",
    "0x1": process.env.REACT_APP_EtherScanMian_RPC_URL || "",
    "5": process.env.REACT_APP_EtherScan_RPC_URL || "",
    "0x5": process.env.REACT_APP_EtherScan_RPC_URL || "",
    "11155111": process.env.REACT_APP_SepoliaETH_RPC_URL || "",
    "0xaa36a7": process.env.REACT_APP_SepoliaETH_RPC_URL || "",
  };
  
  const calculateRpcUrlFromChainId = (chainId: string) => {
    return rpcUrlConfig[chainId as keyof typeof rpcUrlConfig] || "";
  };  

export const switchNetwork = async (chainId: number) => {
  try {
    // Check if MetaMask is installed and connected
    if (window.ethereum) {
      const ethereum = window.ethereum;

      // Send a switch request to MetaMask
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      const accounts = await ethereum.request({ method: "eth_accounts" });
      const updatedChainId = await ethereum.request({ method: "eth_chainId" });
      setWalletData(accounts[0], updatedChainId);
    } else {
      console.error("MetaMask not found. Please install MetaMask.");
    }
  } catch (error) {
    console.error("Error switching network:", error);
  }
};
